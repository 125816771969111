table, tbody, thead, td, th {
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
}

table tr {
    height: 60px !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 4px !important;
}

table tr, table td, table th {
    height: 60px !important;
}

table thead th {
    color: rgba(38, 37, 35, 0.6) !important;
    font-size: 15px !important;
}

table thead tr th:first-child {
    padding-left: 30px !important;
}

table tbody tr {
    cursor: pointer !important;
}

table tbody tr:hover {
    background-color: #E1ECFF !important;
}

table tbody tr:nth-child(odd) {
    background-color: #F6F5F6;
}

table tbody tr:nth-child(even) {
    background-color: transparent;
}

table tbody tr td {
    color: #253163 !important;
    font-weight: 500 !important;
}

table tbody tr th {
    padding-left: 30px !important;
    color: #004479 !important;
    font-weight: 500 !important;
}