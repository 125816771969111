.eventPageHeader button {
  margin-left: 8px;
}

.eventsShowContent > div {
  margin-left: -10px;
}

.eventsShowContent > div > div {
  margin-top: 20px;
}

.eventsShowContent .eventsShowCard {
  padding: 10px 10px;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07) !important;
  border-radius: 10px !important;
  border: none !important;
  transition: all 0.2s;
}

.eventsShowContent .eventsShowCard > div {
  padding: 10px 12px 0;
}

.eventsShowContent .eventsShowCard:hover {
  box-shadow: none !important;
}

.eventsShowHeader {
  line-height: 1.25rem !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
}

.eventStatics {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.eventsStaticsGreen {
  width: 43%;
  height: 38px;
  margin-right: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #64ac84;
  color: #fff;
  border-radius: 25px;
  font-size: 20px;
}

.eventsStaticsRed {
  width: 43%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff523f;
  color: #fff;
  border-radius: 25px;
  font-size: 20px;
}

.eventsReview > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.eventsReviewDivider {
  height: 0.1px;
  background-color: #e7e7e7;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.eventsReview > div p:first-child {
  color: #575757;
}

.eventsReview > div p:last-child {
  color: #2e2e2e;
}

.eventsReviewStatusSpoke {
  color: #339963 !important;
  font-weight: bold;
}

.eventsReview > div:last-child p {
  height: 50px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
}

.eventsVotingNotStarted {
  color: #dbce19 !important;
  font-weight: bold;
}

.eventsVotingEnded {
  color: #d60012 !important;
  font-weight: bold;
}

.eventsReviewStatusSpokeYellow {
  color: #e1ce22 !important;
  font-weight: bold;
}

.eventsReviewStatusQueue {
  color: #898989 !important;
  font-weight: bold;
}

.detailedHeaderStatusRed {
  width: max-content;
  padding: 17px 40px;
  display: flex;
  background-color: #f8d7da;
  color: #ff523f;
  font-size: 18px;
  border-radius: 7px;
  animation: redAnimation 1.6s linear 1.6s infinite alternate;
}

.detailedHeaderStatusBlue {
  width: max-content;
  padding: 17px 40px;
  display: flex;
  background-color: #f8d7da;
  color: #0400d6;
  font-size: 18px;
  border-radius: 7px;
  animation: blueAnimation 1.6s linear 1.6s infinite alternate;
}

.detailedHeaderStatusGreen {
  width: max-content;
  padding: 17px 40px;
  display: flex;
  background-color: #d3ffc7;
  color: #64ac84;
  font-size: 18px;
  border-radius: 7px;
  animation: greenAnimation 1.6s linear 1.6s infinite alternate;
}

/* Panel page */

.panelCards {
  width: calc(20% - 20px) !important;
  margin-top: 16px !important;
  margin-right: 24px;
}

.panelCards:nth-child(5n) {
  margin-right: 0px;
}

@keyframes greenAnimation {
  0% {
    background-color: #d3ffc7;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 20px #d3ffc7;
  }
  50% {
    background-color: #a6ff90;
    left: 200px;
    top: 200px;
    box-shadow: none;
  }
  100% {
    background-color: #d3ffc7;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 20px #d3ffc7;
  }
}

@keyframes redAnimation {
  0% {
    background-color: #f8d7da;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 20px #f8d7da;
  }
  50% {
    background-color: #fc98a1;
    left: 200px;
    top: 200px;
    box-shadow: none;
  }
  100% {
    background-color: #f8d7da;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 20px #f8d7da;
  }
}

@keyframes blueAnimation {
  0% {
    background-color: #f8d7da;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 20px #f8d7da;
  }
  50% {
    background-color: #6facf1;
    left: 200px;
    top: 200px;
    box-shadow: none;
  }
  100% {
    background-color: #f8d7da;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 20px #f8d7da;
  }
}

@media screen and (max-width: 1600px) {
  .eventsShowHeader {
    font-size: 14px;
  }
  .eventsShowContent .eventsShowCard {
    padding: 6px 6px;
  }
  .eventsStaticsGreen {
    width: 47%;
    height: 40px;
    margin-right: 6%;
    border-radius: 25px;
    font-size: 16px;
  }
  .eventsStaticsRed {
    width: 47%;
    height: 40px;
    border-radius: 25px;
    font-size: 16px;
  }
  .eventStatics {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
