/* Vertical version */

@media screen and (max-width: 960px) and (min-width: 600px) {
    html, body, #root {
        overflow-x: hidden !important;
    }
    .navbar {
        padding-left: 10px;
    }
    .css-1jvdimt{
        padding-left: 20px;
    }
}

/* Horizontal version */

@media screen and (max-width: 1360px) and (min-width: 961px) {
    html, body, #root {
        overflow-x: hidden !important;
    }
    .navbar {
        padding-left: 50px;
    }
    .css-1jvdimt{
        padding-left: 20px;
    }
}