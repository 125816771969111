@font-face {
    font-family: "Roboto" !important;
    src: url("../fonts/Roboto-Black.ttf") format("ttf"),
        url("../fonts/Roboto-BlackItalic.ttf") format("ttf"),
        url("../fonts/Roboto-Bold.ttf") format("ttf"),
        url("../fonts/Roboto-BoldItalic.ttf") format("ttf"),
        url("../fonts/Roboto-Italic.ttf") format("ttf"),
        url("../fonts/Roboto-Light.ttf") format("ttf"),
        url("../fonts/Roboto-LightItalic.ttf") format("ttf"),
        url("../fonts/Roboto-Medium.ttf") format("ttf"),
        url("../fonts/Roboto-MediumItalic.ttf") format("ttf"),
        url("../fonts/Roboto-Regular.ttf") format("ttf"),
        url("../fonts/Roboto-Thin.ttf") format("ttf"),
        url("../fonts/Roboto-ThinItalic.ttf") format("ttf"),
}

* {
    font-family: 'Roboto', sans-serif;
}

html, body {
    overflow-x: hidden !important;
}

.segment {
    padding: 25px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(38, 37, 35, 0.07);
    border-radius: 4px;
}

.tab>div:nth-child(n+1) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 12px;
}

.tab .tabMenu {
    border-bottom: 2px solid #E4E4E4;
}

.defaultBtn {
    padding: 10px 35px;
    background: #b8b8b8;
    color: #fff;
    border-radius: 30px;
    text-transform: none;
    font-size: 14px;
    transition: all .5s;
}

.defaultBtn:hover {
    background: #8b8b8b;
}

.mainBtn {
    padding: 10px 35px;
    background: #004479;
    color: #fff;
    border-radius: 30px;
    text-transform: none;
    font-size: 14px;
    transition: all .5s;
}

#demo-simple-select {
    color: #004479 !important;
}

.mainTab .css-rwlgjk-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #004479;
    border-bottom-color: #004479 !important;
}

.mainIconBtn {
    color: #004479 !important;
}

.cancelIconWrap {
    padding-top: 0 !important;
    padding-left: 0 !important;
}

.attachImage {
    width: 400px !important;
    height: 250px !important;
    background-size: cover;
    border-radius: 10px !important;
    margin-bottom: 20px;
}

.imageBox {
    position: relative;
    width: 400px !important;
    height: 250px !important;
    margin-bottom: 10px;
}

.fullScreenIcon {
    position: absolute;
    top: 5px;
    right: 5px;
}

.react-viewer .react-viewer-close {
    top: 82px !important;
    z-index: 1000100 !important;
}

.react-viewer .react-viewer-mask {
    z-index: 1000000 !important;
}

.react-viewer .react-viewer-canvas {
    z-index: 1000050 !important;
}

.react-viewer .react-viewer-footer {
    z-index: 1000050 !important;
}

.quorumWrap {
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    margin-left: 20px;
}

.quorumRedWrap {
    background-color: #FF523F;
}

.quorumQreenWrap {
    background-color: #64AC84;
}

.DashboardLayoutRootClass .css-1jvdimt {
    padding-left: 15px !important;
}

.DashboardLayoutRootClass .MuiPaper-root {
    padding-left: 15px !important;
}

.fullScreenIcon .css-i4bv87-MuiSvgIcon-root {
    font-size: 2rem !important;
}

.voteQuestionsItem {
    padding-top: 0 !important;
}

.usersAvatar {
    display: flex;
    align-items: center;
}

.usersAvatar img {
    width: 50px !important;
    margin-right: 10px;
    height: 50px;
    background-size: cover;
    background-position: center center;
    border: 1.8px solid #253163;
    border-radius: 50%;
}

.pdfBox {
    width: 400px;
    height: 100px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    padding: 1em 1em;
    border-radius: .28571429rem;
    border: 1px solid rgba(34, 36, 38, .15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.pdfBox .title {
    width: 70%;
}

.dialogContent {
    align-items: center;
    text-align: center;
}

.imageFullScreenWrap {
    position: absolute !important;
    height: 100vh;
    width: 100vh;
}

.mainBtn:hover {
    background: #EAC21A;
}

.pageHeader {
    margin-top: -10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
}

.pageHeader div {
    font-size: 20px;
}

.pageHeader>div:first-child {
    display: flex;
    align-items: center;
}

.pageHeader .pageHeaderImg {
    width: 43px;
    height: 43px;
    margin-right: 10px;
    padding: 8px;
    border-radius: 50%;
    background-color: #EAC21A;
}

.pageHeaderImg img {
    width: 100%;
}

/* SideMenu && TopMenu */

.sideMenu>div>div {
    background: #004479;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
}

.sideMenuContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sideMenuContent li {
    margin-bottom: 10px;
}

.sideMenuContent a {
    color: #fff;
}

.logo {
    margin-top: 40px;
    margin-bottom: 30px;
}

.sideMenuDivider {
    height: 0.1px;
    margin-bottom: 10px;
    background-color: #a6a6a69c;
    box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.185);
}

header {
    height: 80px;
}

.navbar {
    padding-left: 255px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
}

.navbarTime {
    display: flex;
    align-items: center;
}

.navbarTime p {
    margin-left: 8px;
    color: #253163;
}

.navbarTime p:first-child {
    margin-bottom: 3px;
    font-size: 18px;
}

.navbarTime p:last-child {
    font-size: 14px;
}

.navbarRight {
    display: flex;
    align-items: center;
    color: #253163;
}

.navbarRight img {
    width: 50.5px;
    height: 50.5px;
    padding: 1.5px;
    background-size: cover;
    background-position: center center;
    border: 1.8px solid #253163;
    border-radius: 50%;
}

.navbarRight p {
    margin-left: 8px;
    margin-right: 8px;
    font-weight: bold;
}

/* Login */

.loginNavbar {
    background-color: #004479 !important;
}

.loginNavbar * {
    color: #fff !important;
}

.segmentPushable {
    /* width: 600px; */
    height: calc(100vh - 90px);
    padding: 25px;
    background-color: #fff;
    box-shadow: -5px 0px 5px rgba(131, 131, 131, 0.1);
    position: absolute;
    right: 0;
    top: 90px;
    bottom: 0;
    z-index: 100000;
    overflow: auto;
}

.segmentPushableHeader {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.segmentPushableHeader .header {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 6px 20px;
    background-color: rgba(11, 94, 255, 0.8);
    color: #fff;
}

.segmentPushableHeader i {
    font-size: 20px;
    cursor: pointer;
}

#demo-simple-select div div {
    border: none !important;
}

.MuiIconButton-colorSecondary {
    color: #004479 !important;
    font-size: 30px !important;
    margin-right: 10px;
}

.MuiIconButton-colorSecondary svg {
    font-size: 30px !important;
}

.eventDocumentsGrid {
    margin-top: 0 !important;
    padding-left: 8px !important;
}

.eventDocumentsList {
    display: flex;
    flex-wrap: wrap;
}

.eventDocumentsList li {
    width: 250px !important;
}

.eventDocumentsList li span {
    color: #469ee2;
    text-decoration: underline;
    cursor: pointer;
}

.eventDocumentsListItems {
    background-color: #f4f6f8;
    border: 1px solid rgb(0 68 121);
    border-radius: 10px;
    text-align: center;
    padding: 11px;
    margin-left: 17px;
}

.photo-viewer-container {
    height: 100% !important;
}

.photo-viewer-container>img {
    align-items: center !important;
    width: 800px !important;
    height: 100% !important;
}

.pg-viewer-wrapper {
    overflow-y: auto;
    align-self: center;
}

.multicolor-bar {
    margin: 20px 5%;
}

.multicolor-bar .values .value {
    float: left;
    text-align: center;
}

.multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
}

.multicolor-bar .bars .bar {
    float: left;
    height: 40px;
}

.multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.multicolor-bar .legends {
    text-align: center;
}


.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
    font-size: 25px;
    vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}

.container {
    display: flex;
    touch-action: none;
    width: 100%;
}

.dropzone {
    flex: 1;
    display: flex;
    min-height: 100%;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
}

.dropzone:empty {
    content: "empty";
    background: #eee;
}

.eventsShowContent .eventsShowCard {
    padding: 10px 10px;
    cursor: pointer;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07) !important;
    border-radius: 10px !important;
    border: none !important;
    transition: all 0.2s;
}